import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import QuestionPopover from '@/uikit/QuestionPopover.vue';
import ManageInventory from '@/Services/components/ManageInventory.vue';
import { useGetServicesByVenueIdQuery } from '@/generated-types/graphql.types';
import DataTable from '@/components/DataTable.vue';
import { DATA_TABLE_SETTINGS } from '@/Services/services.const';
import i18n from '@/i18n/i18n-setup';
const tableLabels = [
    {
        name: 'system_service_name',
        label: i18n.t('services.table.labels.name'),
        sortable: true,
        customElement: 'nameSlot'
    },
    {
        name: 'max_inventory',
        label: i18n.t('services.table.labels.inventory'),
        sortable: true,
        customElement: 'inventorySlot'
    },
    {
        name: 'price',
        label: i18n.t('services.table.labels.price'),
        sortable: true,
        customElement: 'priceSlot'
    },
    {
        name: 'spaces_count',
        label: i18n.t('services.table.labels.space'),
        sortable: true,
        customElement: 'spaceCountSlot'
    },
    {
        name: 'is_mandatory',
        label: i18n.t('services.table.labels.mandatory'),
        sortable: true,
        customElement: 'isMandatorySlot'
    },
    {
        name: 'status',
        label: i18n.t('services.table.labels.status'),
        sortable: true,
        customElement: 'statusSlot'
    }
];
export default defineComponent({
    components: {
        DataTable,
        ManageInventory,
        QuestionPopover
    },
    props: {
        venueId: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const showModal = ref(false);
        const selectedService = ref(null);
        const tableData = ref([]);
        const { result, refetch: refetchServices } = useGetServicesByVenueIdQuery({
            venue_id: props.venueId
        });
        const services = computed(() => result?.value?.inventory_services.filter(Boolean) || []);
        watch(() => props.venueId, () => {
            if (props.venueId) {
                refetchServices({ venue_id: props.venueId });
            }
        }, { immediate: true });
        watch(() => services.value, () => {
            if (services.value) {
                tableData.value = services.value.map((service) => ({
                    venue_id: props.venueId,
                    service_id: service.venue_service_id,
                    spaces_count: service.related_spaces.length,
                    status: service.is_enabled ? 'available' : 'disabled',
                    ...service
                }));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            tableData.value = orderBy(tableData.value, [sortField], [sort]);
        };
        const serviceClicked = (service) => {
            selectedService.value = service;
            showModal.value = true;
        };
        return {
            selectedService,
            showModal,
            tableData,
            tableLabels,
            tableSettings: computed(() => DATA_TABLE_SETTINGS),
            refetchServices,
            serviceClicked,
            updateSort
        };
    }
});
